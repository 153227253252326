import * as React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { Link } from 'gatsby';

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      // <a href={item.link} key={item.text} className="column is-6">
      <div className="section column is-6" key={item.text}>
        <div className="has-text-centered">
          <div
            style={{
              maxWidth: '440px',
              display: 'inline-block',
            }}
          >
            <PreviewCompatibleImage imageInfo={item} />
          </div>
        </div>
        <div className="price">{item.price} zł</div>
        <div className="field w-100 d-flex">
          <a
            className="field  w-100 d-flex"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button is-medium buy-now-btn">Kup Teraz</button>
          </a>
          <Link to={`/blog/${item.bloglink}`} className="w-100">
            <button className="button is-medium is-warning buy-now-btn">
              Pokaż więcej
            </button>
          </Link>
        </div>
        <p>{item.text}</p>
      </div>
      // </a>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
